import "@aws-amplify/ui-react/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Authenticated } from "./components/auth";
import { Home } from "./components/home";
import { TopNavbar } from "./components/nav";
import { Quicksight } from "./components/quicksight";
import { isAdmin } from "./utils/auth";

function App() {
  const [admin, setAdmin] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setAdmin(await isAdmin());
    })();
  }, []);

  return (
    <Authenticated>
      <div className="App">
        <TopNavbar />
        <Routes>
          {admin && <Route path="/dashboard" element={<Quicksight />} />}
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Authenticated>
  );
}

export default App;
