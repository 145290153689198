import React from "react";
import { Spinner } from "react-bootstrap";

type WaitingProps = {
  text: string;
};

export const WaitingScreen = ({ text }: WaitingProps) => (
  <div
    style={{
      backgroundColor: "#186cc7",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: "0",
      width: "100vw",
      position: "fixed"
    }}
  >
    <h2 className="text-light" style={{ marginRight: "5px" }}>
      {text}...
    </h2>
    <Spinner animation="border" variant="light" />
  </div>
);
