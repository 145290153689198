import React, { useEffect, useState } from "react";
import devconnectConfig from "../devconnect.json";
import {
  generateToken,
  getAccessToken,
  initAuth,
  refreshToken,
} from "../utils/auth";
import { parseQueryString } from "../utils/utils";
import { WaitingScreen } from "./waiting";

export interface AuthenticatedProps {
  children: React.ReactNode;
}

setInterval(refreshToken, devconnectConfig.REFRESH_INTERVAL);

export const Authenticated = ({ children }: AuthenticatedProps) => {
  const [session, setSession] = useState<any>(null);

  useEffect(() => {
    console.log("session", session);
    const queryParams = parseQueryString();
    if (queryParams["code"]) {
      if (queryParams["state"] === localStorage.getItem("auth_state")) {
        generateToken(queryParams["code"]).then(() => {
          window.location.search = "";
          setSession(null);
        });
      }
    } else {
      getAccessToken().then((value) => {
        if (value) {
          setSession("active");
        } else {
          setSession(null);
          initAuth();
        }
      });
    }
  });

  return (
    <>
      {session && children}
      {!session && <WaitingScreen text="Please wait authenticating" />}
    </>
  );
};
