export const RootQuery = /* GraphQL */ `
  query MyQuery {
    getProfile {
      email
      id
      jobTitle
      name
      manageId
      hireId
      passions {
        category
        domain
        id
        link
        name
        proficiencies {
          description
          level
        }
        proficiencyLevel
      }
      payHourly
      talents {
        category
        domain
        id
        link
        name
        proficiencies {
          description
          level
        }
        proficiencyLevel
      }
      teamroom
    }
    getRecommendations {
      badgesToEarn {
        category
        domain
        link
        id
        name
        proficiencyLevel
        proficiencies {
          description
          level
        }
        linkedJobs
      }
      passionMatch {
        businessUnit
        description
        id
        requirements {
          category
          domain
          id
          link
          name
          proficiencyLevel
        }
        payHourly
        title
        url
      }
      perfectMatch {
        businessUnit
        description
        id
        payHourly
        requirements {
          category
          id
          domain
          name
          link
          proficiencyLevel
        }
        title
        url
      }
      requirementBadges {
        category
        id
        domain
        link
        name
        proficiencyLevel
      }
      talentMatch {
        businessUnit
        description
        id
        payHourly
        title
        url
      }
    }
  }
`;
