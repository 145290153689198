import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { query } from "../datastore";
import { getQuicksightDashboard } from "../graphql/queries";
import { WaitingScreen } from "./waiting";

export const Quicksight = () => {
  const [embedUrl, setEmbedUrl] = useState<string>();

  useEffect(() => {
    query("dashboard", getQuicksightDashboard)
      .then((response) => {
        setEmbedUrl(response.getQuicksightDashboard.embedUrl);
      })
      .catch((err) => console.error("error fetching dashbord", err));
  }, []);

  return (
    <>
      {!embedUrl && <WaitingScreen text="Please wait loading" />}
      {embedUrl && (
        <Container>
          <br />
          <iframe
            title="Quicksight Dashboard"
            style={{ height: "90vh", width: "70vw" }}
            src={embedUrl}
          ></iframe>
        </Container>
      )}
    </>
  );
};
