/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const setPassions = /* GraphQL */ `
  mutation SetPassions($passions: [PassionInput!]) {
    setPassions(passions: $passions) {
      id
      name
      category
      domain
      proficiencies {
        level
        description
      }
      link
      proficiencyLevel
      linkedJobs
    }
  }
`;
export const putEvent = /* GraphQL */ `
  mutation PutEvent($event: EventInput) {
    putEvent(event: $event) {
      statusCode
      body
    }
  }
`;
export const createProfile = /* GraphQL */ `
  mutation CreateProfile {
    createProfile {
      statusCode
      body
    }
  }
`;
