import React, { useEffect, useState } from "react";
import {
  Badge as PillBadge,
  Modal,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { query } from "../../datastore";
import { setPassions as setPassionsQuery } from "../../graphql/mutations";
import { Badge } from "../../models";
import treeData from "../decisionTree/data.json";
import { DecisionTree } from "../decisionTree/decisionTree";

type PassionsProps = {
  passions: Badge[];
  passionMap: { [name: string]: Badge[] };
  onPassionSaved: Function;
  recordEvent: (event_type: string, event_item: string) => void;
};

export const PassionsPicker = (props: PassionsProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [showMaxPassionsAlert, setShowMaxPassionsAlert] =
    useState<boolean>(false);
  const [passions, setPassions] = useState<string[]>([]);

  useEffect(() => {
    const badgeMap: { [key: string]: string } = {};
    Object.entries(props.passionMap).forEach(([passion, badges]) =>
      badges.forEach((badge) => (badgeMap[badge.id] = passion))
    );
    setPassions(
      Array.from(
        new Set(props.passions.map((passion) => badgeMap[passion.id]))
      ).filter((passion) => passion)
    );
  }, [props]);

  const savePassions = async (newPassions: string[]) => {
    setPassions(newPassions);
    const badges = newPassions
      .flatMap((passion) => props.passionMap[passion])
      .filter((badge) => badge);
    props.recordEvent("passion_articulated", "abcd");
    await query("savePassions", setPassionsQuery, { passions: badges });
    props.onPassionSaved();
  };

  return (
    <>
      <h6>
        {passions.map((passion) => (
          <PillBadge pill bg="primary" style={{ margin: "2px" }} key={passion}>
            {passion}{" "}
            <PillBadge
              bg="danger"
              style={{ cursor: "pointer" }}
              onClick={() => {
                savePassions(passions.filter((ob) => ob !== passion));
              }}
            >
              X
            </PillBadge>
          </PillBadge>
        ))}
        <PillBadge
          pill
          bg="success"
          onClick={() => {
            if (passions.length >= 5) {
              setShowMaxPassionsAlert(true);
              setTimeout(() => {
                setShowMaxPassionsAlert(false);
              }, 5000);
            } else {
              setShowModal(true);
            }
          }}
          style={{ margin: "2px", cursor: "pointer" }}
        >
          Add Passion<PillBadge bg="success">+</PillBadge>
        </PillBadge>
      </h6>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Body>
          <DecisionTree
            tree={treeData}
            optionsToOmit={new Set(passions)}
            onOptionSelected={(option) => {
              setShowModal(false);
              if (option) {
                savePassions([...passions, option]);
              } else {
                setShowAlert(true);
                setTimeout(() => {
                  setShowAlert(false);
                }, 5000);
              }
            }}
          />
        </Modal.Body>
      </Modal>
      <ToastContainer className="p-3" position={"top-end"}>
        <Toast show={showAlert} bg="danger">
          <Toast.Body>
            Oops we were unable to find a passion for you! Please try again.
          </Toast.Body>
        </Toast>
        <Toast show={showMaxPassionsAlert} bg="danger">
          <Toast.Body>
            We can add 5 passions at max. Please remove any existing passion to
            continue.
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};
