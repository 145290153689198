import awsExports from "../aws-exports";
import { getAccessToken } from "../utils/auth";

type QueryResult = {
  data?: object;
  errors?: object;
};

export const query = async (
  name: string,
  query: string,
  variables?: object
) => {
  try {
    const result = await appSyncQuery(query, variables);
    if (result.errors) {
      console.error("graphql errors", result.errors);
      throw new Error("Graphql Errors");
    }
    if (result.data) {
      localStorage.setItem(`QueryResult-${name}`, JSON.stringify(result.data));
      return result.data;
    }
  } catch (err) {
    console.error("Error fetching content", err);
    const localData = localStorage.getItem(`QueryResult-${name}`);
    if (localData) {
      return JSON.parse(localData);
    } else {
      throw err;
    }
  }
};

const appSyncQuery = async (query: string, variables?: object) => {
  const authToken = await getAccessToken();
  if (authToken) {
    const response = await fetch(awsExports.aws_appsync_graphqlEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authToken,
      },
      body: JSON.stringify({ query, variables }),
    });
    if (response.status > 299 || response.status < 200) {
      throw new Error(`${response.status}:${response.statusText}`);
    } else {
      return (await response.json()) as QueryResult;
    }
  } else {
    throw new Error("Token Not Found");
  }
};
