import React, { useEffect, useState } from "react";
import { Question } from "./question";
import { DecisionTree as DecisionTreeType, TreeNode } from "./types";

type DecisionTreeProps = {
  tree: DecisionTreeType;
  onOptionSelected: (option: string | null) => void;
  optionsToOmit: Set<string>;
};

const selectRandom = (items: Array<any>) =>
  items[Math.floor(Math.random() * items.length)];

const terminalNodeOption = "None of these";

export const DecisionTree = (props: DecisionTreeProps) => {
  const [currentNode, setCurrentNode] = useState<TreeNode>(
    props.tree.nodes[props.tree.rootNode]
  );
  const [nodesVisited, setNodesVisited] = useState<Set<string>>(
    new Set<string>()
  );

  useEffect(() => {
    setCurrentNode(props.tree.nodes[props.tree.rootNode]);
    setNodesVisited(new Set<string>());
  }, [props]);

  const getOptions = (node: TreeNode) => {
    const options = Object.keys(currentNode.options).filter(
      (option) => !props.optionsToOmit.has(option)
    );
    if (node.isTerminalNode) {
      options.push(terminalNodeOption);
    }
    return options;
  };

  const restartTree = () => {
    setNodesVisited(new Set<string>());
    const rootNode = props.tree.nodes[props.tree.rootNode];
    setCurrentNode(
      selectRandom(
        Object.entries(rootNode.options)
          .flatMap((entry) => entry[1])
          .map((value) => props.tree.nodes[value])
      )
    );
  };

  const onOptionSelect = (option: string) => {
    if (currentNode.isTerminalNode) {
      if (option === terminalNodeOption) {
        restartTree();
      } else {
        props.onOptionSelected(option);
      }
    } else {
      const nextNodes = currentNode.options[option].filter(
        (node) => !nodesVisited.has(node)
      );
      if (nextNodes.length === 0) {
        restartTree();
      } else {
        const nextNode = selectRandom(nextNodes);
        const copyNodesVisited = new Set<string>(nodesVisited);
        copyNodesVisited.add(nextNode);
        setNodesVisited(copyNodesVisited);
        setCurrentNode(props.tree.nodes[nextNode]);
      }
    }
  };

  return (
    <Question
      question={selectRandom(currentNode.questions)}
      options={getOptions(currentNode)}
      onOptionSelect={onOptionSelect}
    />
  );
};
