import { useEffect, useState } from "react";
import { Badge as PillBadge, Col, Container, Row } from "react-bootstrap";
import { EventInput } from "../API";
import { query } from "../datastore";
import { RootQuery as RootQueryString } from "../datastore/queries";
import { createProfile, putEvent } from "../graphql/mutations";
import { Badge, Opportunity } from "../models";
import { RecommendationType, RootQuery } from "../types";
import passionMap from "./passions/data.json";
import { PassionsPicker } from "./passions/passions";
import { Recommendations } from "./recommendations";
import { Venn } from "./venn";
import { WaitingScreen } from "./waiting";
import './home.css';

export const Home = () => {
  const [recommendationType, setRecommendationType] =
    useState<RecommendationType>("perfect");
  const [data, setData] = useState<RootQuery | null>(null);
  const [recommendations, setRecommendations] = useState<Opportunity[]>([]);
  const [refreshData, setRefreshData] = useState<number>(0);
  const [talents, setTalents] = useState<Badge[]>();
  const [discover, setDiscover] = useState<Badge[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      try {
        const cachedData = localStorage.getItem('QueryResult-rootQuery');
        if (cachedData) {
          const rootQuery = JSON.parse(cachedData) as RootQuery;
          if (rootQuery.getProfile) {
            setData(rootQuery);
            setLoading(false);
          }
        }
        const response = (await query(
          "rootQuery",
          RootQueryString
        )) as RootQuery;
        if (!response.getProfile) {
          await query("createProfile", createProfile);
          setRefreshData(refreshData + 1);
        } else {
          setData(response);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [refreshData]);

  useEffect(() => {
    const recommendationMap = {
      talent: data?.getRecommendations?.talentMatch || [],
      passion: data?.getRecommendations?.passionMatch || [],
      perfect: data?.getRecommendations?.perfectMatch || [],
    };
    setRecommendations(
      recommendationType ? recommendationMap[recommendationType] : []
    );
  }, [data, recommendationType]);

  useEffect(() => {
    setTalents(data?.getProfile.talents || []);
    setDiscover(data?.getRecommendations.badgesToEarn || []);
  }, [data]);

  const recordEvent = async (event_type: string, event_item: string) => {
    const input: EventInput = {
      timestamp: new Date().getTime() + "",
      teamroom: data?.getProfile.teamroom || "",
      item: event_item,
      type: event_type,
    };
    await query("sendEvent", putEvent, { event: input });
  };

  return (
    <>
      {loading && <WaitingScreen text="Please wait loading" />}
      {!loading && (
        <Container>
          <br />
          <Row>
            <Col>
              <h2>Talents</h2>
              <h4>Proven</h4>
              <div>
                <h6>
                  {talents &&
                    talents.length > 0 &&
                    talents.map((e) => (
                      <PillBadge
                        pill
                        bg="primary"
                        style={{ margin: "2px" }}
                        key={e.id}
                      >
                        {e.name}
                      </PillBadge>
                    ))}
                </h6>
                {!data?.getProfile.hireId && (
                  <p>
                    Looks like you don't have a account on XO Hire yet. Please
                    register at{" "}
                    <a href="https://crossover.com/">https://crossover.com</a>{" "}
                    with the email <i>{data?.getProfile.email}</i> and start
                    earning badges!
                  </p>
                )}
                {data?.getProfile.hireId &&
                  (!talents || talents.length === 0) && (
                    <p>Head over to XO Hire and start earning badges now!</p>
                  )}
              </div>
              <h4>Discover</h4>
              <div>
                <h6>
                  {discover &&
                    discover.length > 0 &&
                    discover.map((e) => (
                      <PillBadge
                        pill
                        bg="success"
                        style={{ margin: "2px" }}
                        key={e.id}
                      >
                        {e.name} +
                        {e.linkedJobs !== undefined &&
                          e.linkedJobs > 0 &&
                          <span className="badge bg-warning text-dark linked-jobs">{e.linkedJobs}</span>
                        }
                      </PillBadge>
                    ))}
                </h6>
                {(!discover || discover.length === 0) && (
                  <p>
                    Sorry we don't have any recommendations for you at the
                    moment. Please add some passions
                  </p>
                )}
              </div>
            </Col>
            <Col>
              <h2>Passions</h2>
              <PassionsPicker
                passions={data?.getProfile?.passions || []}
                passionMap={passionMap}
                recordEvent={recordEvent}
                onPassionSaved={() => {
                  setRefreshData(refreshData + 1);
                }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col sm={8} style={{ minHeight: "545px" }}>
              <Venn
                passions={data?.getProfile?.passions || []}
                talents={data?.getProfile?.talents || []}
                opportunities={data?.getRecommendations?.requirementBadges || []}
                setRecommendationType={setRecommendationType}
              />
            </Col>
            <Col>
              <Recommendations
                type={recommendationType}
                opportunities={recommendations}
                recordEvent={recordEvent}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
