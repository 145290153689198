import React, { useEffect, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { isAdmin } from "../utils/auth";

export const TopNavbar = () => {
  const [admin, setAdmin] = useState<boolean>(false);
  useEffect(() => {
    (async () => {
      setAdmin(await isAdmin());
    })();
  }, []);

  return (
    <Navbar bg="light" variant="light">
      <Navbar.Brand style={{ paddingLeft: "20px" }}>
        <img
          src="/favicon.png"
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Career Advisor Logo"
        />{" "}
        Career Advisor{" "}
      </Navbar.Brand>
      {admin && (
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Link className="nav-link" to="/">
              Home
            </Link>
            <Link className="nav-link" to="/dashboard">
              Dashboard
            </Link>
          </Nav>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};
