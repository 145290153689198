export const jsonToQueryString = (json: { [key: string]: string }) => {
  return Object.keys(json)
    .map(function (key) {
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
    .join("&");
};

export const parseQueryString = () => {
  const queryString = window.location.search.substr(1);
  const params: { [key: string]: string } = {};
  queryString.split("&").forEach((part) => {
    const item = part.split("=");
    params[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
  });
  return params;
};
