import React from "react";
import { Container, ListGroup, Row } from "react-bootstrap";
import { BsBoxArrowUpRight } from "react-icons/bs";
import { Opportunity } from "../models";
import { RecommendationType } from "../types";

type RecommendationsProps = {
  type: RecommendationType;
  opportunities: Opportunity[];
  recordEvent: (event_type: string, event_item: string) => void;
};

const headingMap: { [key: string]: string } = {
  perfect: "Perfect Fit",
  passion: "Upskilling Required",
  talent: "Talent Match",
};

const descriptionMap: { [key: string]: string } = {
  perfect: "These are a perfect fit for you given your passions and talents.",
  passion:
    "These are prospective opportunities, but you need to upskill in certain areas for them.",
  talent:
    "These match your talents but you do not seem to have passion for them, so please be sure if you want to go ahead with these.",
};

const colorMap: { [key: string]: string } = {
  perfect: "#825b4f",
  passion: "#b32955",
  talent: "#7c6c28"
}

export const Recommendations = ({
  type,
  opportunities,
  recordEvent
}: RecommendationsProps) => {
  return (
    <div>
      <Container>
        {type && opportunities.length > 0 && (
          <>
            <Row>
              <h2>{headingMap[type]}</h2>
              <hr style={{color: colorMap[type], height: '10px'}} />
              <p>{descriptionMap[type]}</p>
            </Row>
            {opportunities.length > 0 && (
              <ListGroup>
                {opportunities.map((o) => (
                  <ListGroup.Item
                    action
                    onClick={async () => {
                      recordEvent(`${type}_match_clicked`, o.id);
                      window.open(o.url, '_blank')?.focus();
                    }}
                    key={o.id}
                  >
                    {o.title + " "}
                    <BsBoxArrowUpRight style={{ marginBottom: "3px" }} />
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </>
        )}
        {(!type || opportunities.length === 0) && (
          <p style={{ color: "black" }}>
            Sorry, we do not have any recommendations for you at the moment.
            Please hover over the relevant sections on the Venn Diagram or add
            your passions to populate this.
          </p>
        )}
        <br />
      </Container>
    </div>
  );
};
