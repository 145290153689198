import { v4 as uuid } from "uuid";
import config from "../devconnect.json";
import { jsonToQueryString } from "./utils";

export const initAuth = () => {
  console.log("initiating auth");
  const state = uuid().replaceAll("-", "");
  const queryParams = {
    redirect_uri: `${window.location.protocol}//${window.location.host}/`,
    response_type: config.RESPONSE_TYPE,
    client_id: config.CLIENT_ID,
    scope: config.SCOPE.join(" "),
    state,
  };
  localStorage.setItem("auth_state", state);
  window.location.href = `${config.AUTH_ENDPOINT}?${jsonToQueryString(
    queryParams
  )}`;
};

export const generateToken = async (code: string) => {
  console.log("generating token");
  const response = await fetch(config.TOKEN_ENDPOINT, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: jsonToQueryString({
      code,
      grant_type: "authorization_code",
      redirect_uri: `${window.location.protocol}//${window.location.host}/`,
      client_id: config.CLIENT_ID,
    }),
  });
  const body = await response.json();
  localStorage.setItem(
    "auth_token",
    JSON.stringify({
      ...body,
      expiresAt: new Date().getTime() + (body.expires_in - 100) * 100,
    })
  );
};

export const refreshToken = async () => {
  console.log("refreshing token");
  const auth_token = localStorage.getItem("auth_token");
  if (auth_token) {
    const response = await fetch(config.TOKEN_ENDPOINT, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: jsonToQueryString({
        grant_type: "refresh_token",
        refresh_token: JSON.parse(auth_token).refresh_token,
        scope: config.SCOPE.join(" "),
        client_id: config.CLIENT_ID,
      }),
    });
    const body = await response.json();
    if (response.status < 300 && response.status >= 200) {
      localStorage.setItem(
        "auth_token",
        JSON.stringify({
          ...body,
          expiresAt: new Date().getTime() + (body.expires_in - 100) * 100,
        })
      );
    }
  }
};

export const getAccessToken = async (): Promise<string | null> => {
  if (localStorage.getItem("auth_token")) {
    const expiresAt = JSON.parse(
      localStorage.getItem("auth_token") || "{}"
    ).expiresAt;
    if (new Date().getTime() >= expiresAt) {
      refreshToken();
    }
    return JSON.parse(localStorage.getItem("auth_token") || "{}").access_token;
  }
  return null;
};

export const getClaims = async () => {
  const accessToken = await getAccessToken();
  if (accessToken) {
    const jwtBody = JSON.parse(atob(accessToken.split(".")[1]));
    return jwtBody;
  }
  return {};
};

export const isAdmin = async () => {
  const claims = await getClaims();
  const access = new Set(
    claims.access
      .substring(1, claims.access.length - 1)
      .split(",")
      .map((s: string) => s.trim())
  );
  return access.has("RAM-CareerAdvisor-Admin");
};
