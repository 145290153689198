import React, { useEffect, useState } from "react";
import { VennArc, VennDiagram, VennDiagramData, VennSeries } from "reaviz";
import { HOVER_TIMEOUT } from "../constants";
import { Badge } from "../models";
import { RecommendationType } from "../types";

type VennProps = {
  passions: Badge[];
  talents: Badge[];
  opportunities: Badge[];
  setRecommendationType: (recommendationType: RecommendationType) => void;
};

const intersection = (setA: Set<string>, setB: Set<string>) =>
  new Set(Array.from(setA).filter((x) => setB.has(x)));

const mixColors = (colorA: string, colorB: string) => {
  const colorAComponents = colorA.match(/\w\w/g);
  const colorBComponents = colorB.match(/\w\w/g);
  if (colorAComponents && colorBComponents) {
    const [rA, gA, bA] = colorAComponents.map((c) => parseInt(c, 16));
    const [rB, gB, bB] = colorBComponents.map((c) => parseInt(c, 16));
    const r = Math.round(rA + (rB - rA) * 0.5)
      .toString(16)
      .padStart(2, "0");
    const g = Math.round(gA + (gB - gA) * 0.5)
      .toString(16)
      .padStart(2, "0");
    const b = Math.round(bA + (bB - bA) * 0.5)
      .toString(16)
      .padStart(2, "0");
    return "#" + r + g + b;
  }
  return "#000000";
};

const colorMap: { [set: string]: string } = {
  Talent: "#21bf44",
  Passion: "#8f399d",
  Opportunity: "#d6190c",
};

export const Venn = (vennProps: VennProps) => {
  const [data, setData] = useState<VennDiagramData[]>([]);
  const [selections, setSelections] = useState<Array<string>>([
    "Talent|Opportunity|Passion",
  ]);
  const [lastUpdatedTimestamp, setLastUpdatedTimestamp] = useState(0);

  const computeVennData = () => {
    console.log('computing venn data');
    const passionSet = new Set(vennProps.passions.map((e) => e.id));
    const talentSet = new Set(vennProps.talents.map((e) => e.id));
    const opportunitySet = new Set(vennProps.opportunities.map((e) => e.id));
    const vennData = [{ key: ["Opportunity"], data: opportunitySet.size }];
    let region: RecommendationType = null;

    if (talentSet.size > 0) {
      vennData.push({ key: ["Talent"], data: talentSet.size });
      vennData.push({
        key: ["Talent", "Opportunity"],
        data: intersection(talentSet, opportunitySet).size,
      });
      region = "talent";
    }
    if (passionSet.size > 0) {
      vennData.push({ key: ["Passion"], data: passionSet.size });
      vennData.push({
        key: ["Passion", "Opportunity"],
        data: intersection(passionSet, opportunitySet).size,
      });
      region = "passion";
    }
    if (talentSet.size > 0 && passionSet.size > 0) {
      vennData.push({
        key: ["Talent", "Passion"],
        data: intersection(talentSet, passionSet).size,
      });
      vennData.push({
        key: ["Talent", "Opportunity", "Passion"],
        data: intersection(intersection(passionSet, opportunitySet), talentSet)
          .size,
      });
      region = "perfect";
    }
    const selectionsData = {
      talent: ["Talent|Opportunity", "Opportunity|Talent"],
      passion: ["Opportunity|Passion", "Passion|Opportunity"],
      perfect: [
        "Talent|Opportunity|Passion",
        "Talent|Passion|Opportunity",
        "Opportunity|Talent|Passion",
        "Opportunity|Passion|Talent",
        "Passion|Talent|Opportunity",
        "Passion|Opportunity|Talent",
      ],
    };
    if (lastUpdatedTimestamp === 0) {
      vennProps.setRecommendationType(region);
      setSelections(selectionsData[region || "perfect"]);
    }
    setData(vennData);
  };

  useEffect(computeVennData, [vennProps]);

  const onMouseEnter = (e: any) => {
    const recommendationTypeMap: { [key: string]: RecommendationType } = {
      "Opportunity|Talent": "talent",
      "Opportunity|Passion": "passion",
      "Opportunity|Passion|Talent": "perfect",
    };
    const currentTime = Date.now();
    const recommendationType =
      recommendationTypeMap[e.value.sets.sort().join("|")];
    if (
      currentTime - HOVER_TIMEOUT > lastUpdatedTimestamp &&
      recommendationType
    ) {
      setLastUpdatedTimestamp(currentTime);
      vennProps.setRecommendationType(recommendationType);
      setSelections([e.value.key]);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <VennDiagram
        type="venn"
        data={data}
        series={
          <VennSeries
            selections={selections}
            colorScheme={(data, index) => {
              return data.sets
                .map((e: string) => colorMap[e])
                .reduce(mixColors);
            }}
            arc={
              <VennArc
                onMouseEnter={onMouseEnter}
                stroke={(_data, _index, active, hovered) => {
                  if (hovered) {
                    return "blue";
                  } else if (active) {
                    return "green";
                  }
                  return "white";
                }}
              />
            }
          />
        }
      />
    </div>
  );
};
