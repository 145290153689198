import React from "react";
import { Button, Card } from "react-bootstrap";

type QuestionProps = {
  question: string;
  options: string[];
  onOptionSelect: (option: string) => void;
};

export const Question = (props: QuestionProps) => (
  <Card className="text-center">
    <Card.Body>
      <Card.Title>{props.question}</Card.Title>
      <br />
      {props.options.map((option) => (
        <Button
          variant="primary"
          onClick={() => props.onOptionSelect(option)}
          key={option}
          style={{ margin: '5px'}}
        >
          {option}
        </Button>
      ))}
    </Card.Body>
  </Card>
);
