/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getProfile = /* GraphQL */ `
  query GetProfile {
    getProfile {
      id
      email
      jobTitle
      payHourly
      name
      teamroom
      manageId
      hireId
      talents {
        id
        name
        category
        domain
        proficiencies {
          level
          description
        }
        link
        proficiencyLevel
        linkedJobs
      }
      passions {
        id
        name
        category
        domain
        proficiencies {
          level
          description
        }
        link
        proficiencyLevel
        linkedJobs
      }
    }
  }
`;
export const getRecommendations = /* GraphQL */ `
  query GetRecommendations {
    getRecommendations {
      perfectMatch {
        id
        title
        description
        payHourly
        businessUnit
        requirements {
          id
          name
          category
          domain
          link
          proficiencyLevel
          linkedJobs
        }
        url
      }
      passionMatch {
        id
        title
        description
        payHourly
        businessUnit
        requirements {
          id
          name
          category
          domain
          link
          proficiencyLevel
          linkedJobs
        }
        url
      }
      talentMatch {
        id
        title
        description
        payHourly
        businessUnit
        requirements {
          id
          name
          category
          domain
          link
          proficiencyLevel
          linkedJobs
        }
        url
      }
      badgesToEarn {
        id
        name
        category
        domain
        proficiencies {
          level
          description
        }
        link
        proficiencyLevel
        linkedJobs
      }
      requirementBadges {
        id
        name
        category
        domain
        proficiencies {
          level
          description
        }
        link
        proficiencyLevel
        linkedJobs
      }
    }
  }
`;
export const getQuicksightDashboard = /* GraphQL */ `
  query GetQuicksightDashboard {
    getQuicksightDashboard {
      embedUrl
    }
  }
`;
export const listBadges = /* GraphQL */ `
  query ListBadges {
    listBadges {
      id
      name
      category
      domain
      proficiencies {
        level
        description
      }
      link
      proficiencyLevel
      linkedJobs
    }
  }
`;
